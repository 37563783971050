import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import { Link } from "react-router-dom";

import D from "../../assets/Images/colorful kits.jpg";
import V from "../../assets/Images/TFCH outreach in kogo with banner.jpg";

function About() {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Get the current URL
    const hash = window.location.hash;

    if (hash) {
      // Scroll to the specific section
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  const datas = [
    {
      title: "Our Mission & Vision",
      description:
        "Traffina Foundation for Community Health works around the globe to save lives, defeat poverty, and achieve social justice. We seek a world of hope, inclusion, and social justice, where poverty has been overcome and all people live with dignity and security.",
      btn: "Read More",
      image: D,
      link: "/about-us/mission-vission",
    },
    {
      title: "TFCH Story: A Timeline",
      description:
        "Anticipating a baby’s birth should bring great joy and excitement. But for many African women, pregnancy is a time filled with anxiety and fear. Because of inadequate birthing supplies and limited access to proper care, pregnancy often brings unbearable pain and many times death for their baby, themselves, or both.",
      btn: "Read More",
      image: V,
      link: "/about-us/our-story",
    },
  ];
  return (
    <>
      <Banner
        title="About Us"
        description="We save lives, and fight for women and children."
      />
      <div
        className="container-xl px-3 px-md-5"
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <div className="row align-items-center">
          {/* Right column with YouTube video (will be first on small devices) */}
          <div className="col-12 col-md-6 order-first order-md-last mb-5 mb-md-0">
            <div className="video-container">
              <iframe
                className="video-iframe"
                src="https://www.youtube.com/embed/nZh_J7SREuU"
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          {/* Left column with text content */}
          <div className="col-12 col-md-6">
            <p>
              <span className="text-success fw-bold">
                Traffina Foundation for Community Health Nigeria (TFCH)
              </span>{" "}
              is an indigenous non-governmental organization that exists to
              complement the efforts of the Federal Health departments, local
              implementing Partners, International Aid Agencies, and Civil
              Society organizations in promoting and implementing Maternal and
              Child Health programs in Nigeria.
            </p>
            <p>
              Traffina foundation has been on the frontline of promoting
              maternal and child health programs by providing door-to-door
              antenatal services, distributing free clean delivery kits,
              engaging in community advocacies on male involvement in maternal
              and child health, mapping and training Traditional Birth
              Attendants, and offering free medical care services to children in
              rural communities.
            </p>
          </div>
        </div>
      </div>

      <div>
        <div
          className="container-xl"
          style={{
            backgroundColor: "#EBFBF7",
            marginBottom: "100px",
            padding: "50px",
          }}
        >
          {/* <h2>Here’s how you can help!</h2> */}
          {/* <p>
            From advocacy, to donating money, volunteering and more, there are
            many ways you can help.
          </p> */}
          <div className="row g-4">
            {datas.map((data, index) => (
              <div className="col-md-6" key={index}>
                <div className="card mb-4 h-100">
                  <img
                    src={data.image}
                    className="card-img-top"
                    alt={data.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{data.title}</h5>
                    <p className="card-text">{data.description}</p>
                    <Link
                      to={data.link}
                      className="btn"
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: "#00715D",
                        borderRadius: "0px",
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {data.btn}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
