// import React from "react";
// import "./Features.css"; // Optional, if you need custom styles

// function Features() {
//   return (
//     <div
//       className="container-fluid px-3 px-md-5"
//       style={{
//         marginTop: "100px",
//       }}
//     >
//       <div className="row justify-content-center">
//         {/* Card 1 */}
//         <div className="col-md-3 mb-4">
//           <div
//             className="card text-white d-flex flex-column"
//             style={{
//               backgroundColor: "#00715D",
//               borderRadius: "0px",
//               height: "100%",
//             }}
//           >
//             <div className="card-body d-flex flex-column text-center">
//               <div className="icon-container mb-3">
//                 <i
//                   className="bi bi-hand-thumbs-up"
//                   style={{
//                     fontSize: "48px",
//                     display: "block",
//                     margin: "0 auto",
//                   }}
//                 ></i>
//               </div>
//               <h5 className="card-title">We're efficient.</h5>
//               <p className="card-text flex-grow-1">
//                 Your donation does more when you give it to Traffina Foundation.
//                 90% of the money Traffina Foundation spends goes toward our
//                 lifesaving work that saves and transforms lives.
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Card 2 */}
//         <div className="col-md-3 mb-4">
//           <div
//             className="card text-white d-flex flex-column"
//             style={{
//               backgroundColor: "#00715D",
//               borderRadius: "0px",
//               height: "100%",
//             }}
//           >
//             <div className="card-body d-flex flex-column text-center">
//               <div className="icon-container mb-3">
//                 <i
//                   className="bi bi-globe"
//                   style={{
//                     fontSize: "48px",
//                     display: "block",
//                     margin: "0 auto",
//                   }}
//                 ></i>
//               </div>
//               <h5 className="card-title">We're far-reaching.</h5>
//               <p className="card-text flex-grow-1">
//                 We are in 36 states including FCT in Nigeria.
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Card 3 */}
//         <div className="col-md-3 mb-4">
//           <div
//             className="card text-white d-flex flex-column"
//             style={{
//               backgroundColor: "#00715D",
//               borderRadius: "0px",
//               height: "100%",
//             }}
//           >
//             <div className="card-body d-flex flex-column text-center">
//               <div className="icon-container mb-3">
//                 <i
//                   className="bi-arrows-move"
//                   style={{
//                     fontSize: "48px",
//                     display: "block",
//                     margin: "0 auto",
//                   }}
//                 ></i>
//               </div>
//               <h5 className="card-title">We're locally led. </h5>
//               <p className="card-text flex-grow-1">
//                 97% of our staff are from the states or regions where we work.
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Card 4 */}
//         <div className="col-md-3 mb-4">
//           <div
//             className="card text-white d-flex flex-column"
//             style={{
//               backgroundColor: "#00715D",
//               borderRadius: "0px",
//               height: "100%",
//             }}
//           >
//             <div className="card-body d-flex flex-column text-center">
//               <div className="icon-container mb-3">
//                 <i
//                   className="bi bi-shield-check"
//                   style={{
//                     fontSize: "48px",
//                     display: "block",
//                     margin: "0 auto",
//                   }}
//                 ></i>
//               </div>
//               <h5 className="card-title">We're trusted.</h5>
//               <p className="card-text flex-grow-1">
//                 With nearly 80 years of service, Traffina Foundation has a
//                 history of building community trust. We listen to what people
//                 want and develop solutions they need.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Features;

import React from "react";
import "./Features.css";

const features = [
  {
    icon: "bi-hand-thumbs-up",
    title: "We're efficient.",
    text: "Your donation does more when you give it to Traffina Foundation For Community Healthy.",
  },
  {
    icon: "bi-globe",
    title: "We're far-reaching.",
    text: "We are one of the largest networks of humanitarians in the africa, including the FCT in Nigeria, and are still expanding to other states.",
  },
  {
    icon: "bi-arrows-move",
    title: "We're locally led.",
    text: "97% of our staff are from the states or regions where we work.",
  },
  {
    icon: "bi-shield-check",
    title: "We're trusted.",
    text: "With years of service, Traffina Foundation has a history of building community trust.",
  },
];

function Features() {
  return (
    <div
      className="container-fluid px-3 px-md-5"
      style={{
        marginTop: "100px",
      }}
    >
      <div className="row justify-content-center">
        {features.map((feature, index) => (
          <div className="col-md-3 mb-4" key={index}>
            <div
              className="card text-white d-flex flex-column"
              style={{
                backgroundColor: "#00715D",
                borderRadius: "0px",
                height: "100%",
              }}
            >
              <div className="card-body d-flex flex-column text-center">
                <div className="icon-container mb-3">
                  <i
                    className={`bi ${feature.icon}`}
                    style={{
                      fontSize: "48px",
                      display: "block",
                      margin: "0 auto",
                    }}
                  ></i>
                </div>
                <h5 className="card-title">{feature.title}</h5>
                <p className="card-text flex-grow-1">{feature.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
